<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :is-sync="() => { return true }"
      :list-items="listItems"
      :brand-list="brandList"
      :show-brands-filter="true"
      :show-search-filter="true"
      :is-editable="false"
      sync-title="Sync HS"
      row-click-path="/tables/users"
      endpoint="users"
      @create="createUser()"
      @delete="id => deleteUser(id)"
      @restore="id => restoreUser(id)"
      @sync="syncUser"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-form
          ref="userForm"
          v-model="valid"
          lazy-validation>
          <v-container grid-list-xl>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.firstName"
                  label="First name"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.lastName"
                  label="Last name"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.email"
                  label="Email"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.phone"
                  label="Phone"
                />
              </v-flex>
              <v-flex
                xs12
                sm5>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.country"
                  label="Country"
                />
              </v-flex>
              <v-flex
                xs12
                sm4>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.language"
                  label="Language"
                />
              </v-flex>
              <v-flex
                xs12
                sm2>
                <v-switch
                  v-model="editDialog.data.active"
                  label="Active"
                />
              </v-flex>
              <v-flex
                v-if="roleList"
                xs12
                sm6>
                <v-select
                  :items="Object.keys(roleList)"
                  :rules="[v => v.length>0 || 'Please select at least 1 user role']"
                  v-model="editDialog.data.roles"
                  multiple
                  label="Roles"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-select
                  v-if="brandList.length"
                  :items="brandList"
                  :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                  v-model="editDialog.data.brands"
                  :item-text="(val) => { return val.name }"
                  clearable
                  item-value="id"
                  multiple
                  label="Brands"
                />
              </v-flex>
              <v-flex
                xs12
                sm12>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.plainPassword"
                  :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                  :type="showPasswordField ? 'text' : 'password'"
                  label="Plain password"
                  @click:append="showPasswordField = !showPasswordField"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import Roles from '../../../helpers/roles'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'

export default {
  components: { CrudTable },
  mixins: [Request, Brands, Roles],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      valid: true,
      brandList: [],
      roleList: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        // { text: 'Phone', value: 'phone', valueKey: 'number' },
        { text: 'First name', value: 'firstName' },
        { text: 'Last name', value: 'lastName' },
        { text: 'Language', value: 'language' },
        { text: 'Country', value: 'country' },
        { text: 'Checked onboarding at', value: 'checkedAt' },
        { text: 'Accepted terms at', value: 'acceptedTermsAt' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Active', value: 'active' },
        { text: 'Roles', value: 'roles' },
        { text: '', value: null }
      ],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      showPasswordField: false
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
      this.roleList = await this.fetchRoles()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    async syncUser (userId, callback) {
      try {
        await this.request('POST', `/hubspot/user/${userId}/sync`)
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Sync HS successfull'
        })
        callback()
      } catch (error) {
      }
    },
    createUser () {
      this.editDialog.display = true
      this.editDialog.data = {
        plainPassword: this.gFunc.generatePassword(32),
        country: 'NL',
        language: 'NL'
      }
      this.showPasswordField = false
      this.editDialog.saveFunction = () => {
        if (this.$refs.userForm.validate()) {
          let body = {
            data: {
              'active': this.editDialog.data.active,
              'email': this.editDialog.data.email,
              'name': this.editDialog.data.firstName + ' ' + this.editDialog.data.lastName,
              'phone': this.editDialog.data.phone,
              'country': this.editDialog.data.country,
              'language': this.editDialog.data.language,
              'roles': this.editDialog.data.roles != null ? this.editDialog.data.roles.join(',') : null,
              'brands': this.editDialog.data.brands.map(brand => brand.id || brand),
              'plainPassword': this.editDialog.data.plainPassword
            }
          }

          this.request('POST', `/users`, body, ({ data }) => {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Gebruiker is aangemaakt'
            })
          })
        }
      }
    },
    deleteUser (id) {
      this.request('DELETE', `/users/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Gebruiker is verwijderd'
        })
      })
    },
    restoreUser (id) {
      this.request('PATCH', `/trash/un-delete/user/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'User is restored'
        })
      })
    },
    editUser (data) {
      this.showPasswordField = false
      this.editDialog.display = true
      this.editDialog.data = {
        ...this.gFunc.deepCopy(data),
        phone: data.phone ? data.phone.number : ''
      }
      this.editDialog.saveFunction = () => {
        if (this.$refs.userForm.validate()) {
          let body = {
            data: {
              'active': this.editDialog.data.active,
              'email': this.editDialog.data.email,
              'name': this.editDialog.data.firstName + ' ' + this.editDialog.data.lastName,
              'phone': this.editDialog.data.phone,
              'country': this.editDialog.data.country,
              'language': this.editDialog.data.language,
              'roles': this.editDialog.data.roles.join(','),
              'brands': this.editDialog.data.brands.map(brand => brand.id || brand),
              'plainPassword': this.editDialog.data.plainPassword
            }
          }

          this.request('PATCH', `/users/${data.id}`, body, ({ data }) => {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Gebruiker is gewijzigd'
            })
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
